/**
 * Removes UTC offset from a date string.
 * @param date - The date string with an added UTC offset to convert coming from DynamoDB.
 * @param utcOffset - The UTC offset in hours.
 * @returns The time date string without UTC offset in ISO 8601 format.
 */
export const removeUtcOffset = (date?: string | null, utcOffset?: number | null) => {
   if (date === undefined || date === null || date === '') return null;
   const parsedDate = Date.parse(date);
   if (isNaN(parsedDate)) return null;
   const offsetDate = new Date(parsedDate);
   const utcOffsetMilliseconds = utcOffset ? utcOffset * 60 * 60 * 1000 : 0; 
   const utcDate = new Date(offsetDate.getTime() - utcOffsetMilliseconds);
   return utcDate.toISOString();
};

export const utcToNaiveStartDate = (dateString: string) => {
   const date = new Date(Date.parse(dateString));
   date.setUTCHours(0);
   date.setUTCMinutes(0);
   date.setUTCSeconds(0);
   return date.toISOString().replace('.000Z', '');
};

export const utcToNaiveEndDate = (dateString: string) => {
   const date = new Date(Date.parse(dateString));
   date.setUTCHours(0);
   date.setUTCDate(date.getUTCDate() - 1);
   date.setUTCHours(23);
   date.setUTCMinutes(59);
   date.setUTCSeconds(59);
   return date.toISOString().replace('.000Z', '');
};
